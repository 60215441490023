import React from 'react';

const AboutPage = (props) => {
    return (
        <pre  className="layout__item u-1/2-lap-and-up">
            {props.rawJSON}
        </pre>
    );
};

export default AboutPage;