import YoutubeMediumPage from 'pages/Dashboard/Widgets/Youtube/YoutubeMedium';
import React from 'react';
import useRequest from '../../../../hooks/use-request';
import { YOUTUBE_COMMENTS_API_URL } from '../../../../utils/api';

const YoutubeMedium = (props) => {
    const [handleRequest, status] = useRequest()
    const [data, setData] = React.useState()

    React.useEffect(() => {
        if (props.demo) {
            return
        }
        handleRequest("get", YOUTUBE_COMMENTS_API_URL(props.video_id), {}, {}, (data, status) => {
            if (status === "Done") {
                setData(data);
            } else if (status === "Error") {
                console.error(data);
            }
        }, true)
    }, [])

    const handleReload = () => {
        handleRequest("get", YOUTUBE_COMMENTS_API_URL(props.video_id), {}, {}, (data, status) => {
            if (status === "Done") {
                setData(data);
            } else if (status === "Error") {
                console.error(data);
            }
        }, true)
    }

    return (
        <YoutubeMediumPage
            {...props}
            handleReload={handleReload}
            status={status}
            data={data}
        />
    );
};

export default YoutubeMedium;