import React from 'react';
import Widget from 'containers/Dashboard/Widgets/Widget';
import Avatar from '../../../../components/profile/avatar/avatar';
import moment from 'moment';
import { MoonLoader } from 'react-spinners';

const YoutubeVideoComment = (props) => {
    const commentData = props.snippet?.top_level_comment?.snippet;

    return (
        <div className={"py-1 " + (props.isLast ? "" : "border-bottom")}>
            <div className="d-flex align-items-center">
                <Avatar img={commentData?.author_profile_image_url} size="xs" />
                <p className="m-0 text-secondary pl-1">{commentData?.author_display_name}</p>
                <small className="text-muted pl-1">- {moment(commentData?.published_at).format("DD/MM/YYYY - HH:mm")}</small>
            </div>
            <p className="py-1 m-0">{commentData?.text_display}</p>
        </div>
    )
}

const YoutubeMediumPage = (props) => {
    const comments = props.data?.items;

    return (
        <div className="col-12 col-md-6 py-1">
            <Widget {...props} onReload={props.handleReload}>
                {
                    props.status === "Loading" &&
                    <div className="position-absolute d-flex justify-content-center align-items-center" style={{top: 0, left: 0, right: 0, bottom: 0}}>
                        <MoonLoader
                            size={60}
                            color={"#1faf46"}
                            loading={props.status === "Loading"}
                        />
                    </div>
                }
                {
                    comments ?
                    <div className="w-100">
                        <h2 className="text-dark">Last comments for <a href={props.widget.video} className="text-primary" target="_blank" rel="noreferrer" >this video</a></h2>
                        {
                            comments.map((el, i) => <YoutubeVideoComment {...el} key={el.id} isLast={i === comments.length - 1} />)
                        }
                    </div>
                    :
                        props.demo ?
                        <div className="w-100">
                            <h2>Last comments for</h2>
                            <div className="bg-dark mb-1" style={{height: 20, width: 30}}></div>
                            <div className="bg-dark mb-1" style={{height: 40, width: 140}}></div>
                            <div className="bg-dark mb-1" style={{height: 20, width: 30}}></div>
                            <div className="bg-dark mb-1" style={{height: 40, width: 200}}></div>
                            <div className="bg-dark mb-1" style={{height: 20, width: 30}}></div>
                            <div className="bg-dark mb-1" style={{height: 40, width: 180}}></div>
                        </div>
                        :
                        <p>We didn't found this video...</p>
                }
            </Widget>
        </div>
    );
};

export default YoutubeMediumPage;