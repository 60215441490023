import React from 'react';
import { StyledFirebaseAuth } from 'react-firebaseui';
import firebase from 'firebase';

const LoginPage = ({ uiConfig, verifyEmail }) => {
    return (
        <div className="w-100 vh-100 d-flex justify-content-center align-items-center bg-login">
            <div className="card shadow-lg" style={{minWidth: 50+'vh'}}>
                <div className="card-body pb-4">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <img src="/assets/img/DashboardLogoSVG.svg" alt="Dashboard logo" width="100" />
                        <h1 className="text-center font-weight-bolder text-primary py-2">Dashboard</h1>
                    </div>
                    {
                        verifyEmail &&
                        <div className="bg-danger p-2 rounded w-100 pb-2">
                            <p className="text-white m-0">Please confirm your email address before. An email has been send to your inbox.</p>
                        </div>
                    }
                    <div className="px-2">
                        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;