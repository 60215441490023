import React from 'react';
import Widget from 'containers/Dashboard/Widgets/Widget';
import { MoonLoader } from 'react-spinners';

const YoutubeLittlePage = (props) => {
    const videoData = props.data?.items[0]

    return (
        <div className="col-12 col-md-3 py-1">
            <Widget {...props} onReload={props.handleReload}>
                {
                    props.status === "Loading" &&
                    <div className="position-absolute d-flex justify-content-center align-items-center" style={{top: 0, left: 0, right: 0, bottom: 0}}>
                        <MoonLoader
                            size={60}
                            color={"#1faf46"}
                            loading={props.status === "Loading"}
                        />
                    </div>
                }
                {
                    videoData ?
                    <div className="d-flex flex-column justify-content-center align-items-center pb-3">
                        <img alt="" className="rounded py-2 w-100" src={videoData?.snippet?.thumbnails?.medium?.url} size="xl" />
                        <p className="text-center pt-1 m-0">{videoData?.snippet?.title}</p>
                        <h2 className="text-primary text-center m-0 pt-2">{videoData?.statistics?.view_count}</h2>
                        <p className="text-center text-muted pt-0 mt-0">Views</p>
                    </div>
                    :
                        props.demo ?
                        <div className="d-flex flex-column justify-content-center align-items-center pb-3">
                            <div className="bg-dark mb-1" style={{height: 80, width: 80}}></div>
                            <div className="bg-dark mb-1" style={{height: 30, width: 100}}></div>
                            <div className="bg-dark mb-1" style={{height: 30, width: 80}}></div>
                            <p className="text-center text-muted pt-0 mt-0">Views</p>
                        </div>
                        :
                        <p>We didn't found this video...</p>
                }
            </Widget>
        </div>
    );
};

export default YoutubeLittlePage;