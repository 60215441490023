import React from 'react';
import AboutPage from '../../pages/About/AboutPage';
import json from './about.json';

const About = () => {
    const [data, setData] = React.useState(json)

    React.useEffect(() => {
        const dataUpdated = {...data};

        fetch("https://api.ipify.org").then(res => res.text()).then(res => {
            dataUpdated.customer.host = res
            dataUpdated.server.current_time = Math.round((new Date()).getTime() / 1000|0)
            setData(dataUpdated)
        })

    }, [])

    if (data.customer?.host) {
        return (
            <AboutPage rawJSON={JSON.stringify(data, null, 4)} />
        );
    }
    return null;
};

export default About;