import React from 'react';
import ConfigurationServicesList from './ConfigurationViews/ConfigurationServices/ConfigurationServicesList';
import ConfigurationWidgetPage from './ConfigurationViews/ConfigurationWidget/ConfigurationWidgetPage';

const ConfigurationPage = (props) => {
    return (
        <div className="container-fluid pt-4">
            <div className="row">
                <div className={"col-12 col-md-4 d-md-block " + (!props.serviceSelected ? "d-block" : "d-none")}>
                    <ConfigurationServicesList services={props.services} serviceSelected={props.serviceSelected} onSelect={props.onSelect} />
                </div>
                <div className={"col-12 col-md-8 d-md-block " + (props.serviceSelected ? "d-block" : "d-none")}>
                    <ConfigurationWidgetPage serviceSelected={props.serviceSelected} onSelect={props.onSelect} />
                </div>
            </div>
        </div>
    );
};

export default ConfigurationPage;