import Card from 'components/design/card/card';
import React from 'react';
import Carousel from "react-bootstrap-carousel";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";

const ConfigurationWidgetCarouselElement = (props) => {
    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <props.view demo={true} />
        </div>
    )
}

const ConfigurationWidgetForm = (props) => {
    if (props.view)
        return <props.view
            serviceSelected={props.serviceSelected}
            widgetSelected={props.widgetSelected}
        />
    return null
}

const ConfigurationWidgetPage = (props) => {
    const [index, setIndex] = React.useState(0);
    const carousel = React.useRef();

    React.useEffect(() => {
        setIndex(0)
        carousel.current.goToSlide(0);
    }, [props.serviceSelected?.name])

    const handleSelect = (s) => {
        setIndex(s);
    }

    return (
        <Card className="bg-white shadow">
            <div className="p-2 d-flex justify-content-between align-items-center border-bottom">
                <h3 className="d-flex align-items-center w-100">
                    <img style={{cursor: 'pointer'}} onClick={() => props.onSelect()} className="pr-1 d-md-none" src="/assets/media/svg/icons/Navigation/Angle-left.svg" alt="" />
                    Widget - <small className="m-0 pl-1 text-muted">{props.serviceSelected?.name || "Service"}</small>
                </h3>
                {
                    index < props.serviceSelected?.widgets?.length &&
                    <p className="m-0 w-100 text-right text-primary">{props.serviceSelected?.widgets[index].title}</p>
                }
            </div>
            <div className="card-body">
                {
                    !props.serviceSelected &&
                    <p className="text-muted">Select a service to see its widgets</p>
                }
                <Carousel version={4} autoplay={false} defaultActiveIndex={index} ref={carousel} onSelect={handleSelect} rightIcon={<img src="/assets/media/svg/icons/Navigation/Angle-right.svg" alt="" />} leftIcon={<img src="/assets/media/svg/icons/Navigation/Angle-left.svg" alt="" />}>
                    {
                        props.serviceSelected?.widgets?.map(el => <ConfigurationWidgetCarouselElement key={el.id} {...el} />)
                    }
                </Carousel>
            </div>
            <div className="px-2">
                <ConfigurationWidgetForm widgetSelected={props.serviceSelected?.widgets[index]} serviceSelected={props.serviceSelected} view={props.serviceSelected?.widgets[index]?.configuration} />
            </div>
        </Card>
    );
};

export default ConfigurationWidgetPage;