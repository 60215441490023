import React from 'react';
import LoginPage from '../../pages/Auth/LoginPage';
import useFirebase from 'hooks/Firebase/useFirebase';
import { Redirect } from 'react-router-dom';


const Login = () => {
    const [{app, verifyEmail, user}] = useFirebase();

    const uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/',
        signInOptions: [
            app.auth.EmailAuthProvider.PROVIDER_ID,
            app.auth.PhoneAuthProvider.PROVIDER_ID,
            app.auth.GoogleAuthProvider.PROVIDER_ID,
            app.auth.GithubAuthProvider.PROVIDER_ID,
        ]
    }

    if (user) {
        return <Redirect to="/" />
    }

    return (
        <LoginPage uiConfig={uiConfig} verifyEmail={verifyEmail} />
    );
};

export default Login;