import React from 'react';
import { FirebaseContext } from './FirebaseProvider'

const useFirebase = () => {
    const firebase = React.useContext(FirebaseContext);

    const addDatabase = React.useMemo(() => ({
        widget(coll, data) {
            const db = firebase.app.firestore();

            setTimeout(() => {
                firebase.updateData()
            }, 500);
            db.collection(coll).doc(firebase.user?.uid).update({
                widgets: firebase.app.firestore.FieldValue.arrayUnion(data)
            })
        },
        setWidget(coll, data, update = true) {
            const db = firebase.app.firestore();

            if (update) {
                setTimeout(() => {
                    firebase.updateData()
                }, 500);
            }
            db.collection(coll).doc(firebase.user?.uid).update({
                widgets: data
            })
        }
    }), [firebase])

    const deleteDatabase = React.useMemo(() => ({
        widget(coll, data) {
            const db = firebase.app.firestore();

            setTimeout(() => {
                firebase.updateData()
            }, 500);
            db.collection(coll).doc(firebase.user?.uid).update({
                widgets: firebase.app.firestore.FieldValue.arrayRemove(data)
            })
        }
    }), [firebase])

    return [firebase, addDatabase, deleteDatabase];
};

export default useFirebase;