import ExchangeLittleForm from "containers/Configuration/WidgetsForms/Exchange/ExchangeLittle";
import ExchangeMediumForm from "containers/Configuration/WidgetsForms/Exchange/ExchangeMedium";
import WeatherLittleForm from "containers/Configuration/WidgetsForms/Weather/WeatherLittle";
import WeatherMediumForm from "containers/Configuration/WidgetsForms/Weather/WeatherMedium";
import YoutubeBigForm from "containers/Configuration/WidgetsForms/Youtube/YoutubeBig";
import YoutubeLittleForm from "containers/Configuration/WidgetsForms/Youtube/YoutubeLittle";
import YoutubeMediumForm from "containers/Configuration/WidgetsForms/Youtube/YoutubeMedium";

import WeatherLittle from "containers/Dashboard/Widgets/Weather/WeatherLittle";
import WeatherMedium from "containers/Dashboard/Widgets/Weather/WeatherMedium";
import YoutubeLittle from "containers/Dashboard/Widgets/Youtube/YoutubeLittle";
import YoutubeMedium from "containers/Dashboard/Widgets/Youtube/YoutubeMedium";
import YoutubeBig from "containers/Dashboard/Widgets/Youtube/YoutubeBig";
import ExchangeLittle from "containers/Dashboard/Widgets/Exchange/ExchangeLittle";
import ExchangeMedium from "containers/Dashboard/Widgets/Exchange/ExchangeMedium";

const services = [
    {
        id: 1,
        name: "Weather",
        img: "/assets/img/weather-logo.png",
        widgets: [
            {
                id: 11,
                name: "day_forecast",
                title: "Single day forecast",
                view: WeatherLittle,
                configuration: WeatherLittleForm
            },
            {
                id: 12,
                name: "three_day_forecast",
                title: "Next 3 days forecast",
                view: WeatherMedium,
                configuration: WeatherMediumForm
            }
        ]
    },
    {
        id: 2,
        name: "Youtube",
        img: "/assets/img/youtube-logo.png",
        widgets: [
            {
                id: 21,
                name: "video_views",
                title: "Show the views of a video",
                view: YoutubeLittle,
                configuration: YoutubeLittleForm
            },
            {
                id: 22,
                name: "video_comments",
                title: "Show the comments from a video",
                view: YoutubeMedium,
                configuration: YoutubeMediumForm
            },
            {
                id: 23,
                name: "channel_subscribers",
                title: "Show the number of subscribers of a channel",
                view: YoutubeBig,
                configuration: YoutubeBigForm
            }
        ]
    },
    {
        id: 3,
        name: "Exchange",
        img: "/assets/img/stonks-logo.png",
        widgets: [
            {
                id: 31,
                name: "exchange_rate",
                title: "Exchange rate between two currency pair",
                view: ExchangeLittle,
                configuration: ExchangeLittleForm
            },
            {
                id: 32,
                name: "stock_range",
                title: "Evolution of the price of a stock share",
                view: ExchangeMedium,
                configuration: ExchangeMediumForm
            }
        ]
    }
]

export default services;