import { Button } from 'components/form/button';
import InputFormik from 'components/form/input/inputFormik';
import React from 'react';

const ExchangeLittleFormPage = (props) => {
    return (
        <>
            <InputFormik {...props} {...props.formData?.base} />
            <InputFormik {...props} {...props.formData?.symbol} />
            <InputFormik {...props} {...props.formData?.timer} />
            <div className="d-flex justify-content-end">
                {
                    props.handleEdit &&
                    <div className="mr-1">
                        <Button color="dark" title="Cancel" onClick={props.handleEdit} />
                    </div>
                }
                <Button type="submit" title={props.handleEdit ? "Save" : "Add to dashboard"} />
            </div>
        </>
    );
};

export default ExchangeLittleFormPage;