import WidgetForm from 'containers/Configuration/WidgetsForms/WidgetForm';
import React from 'react';
import {Modal} from 'react-bootstrap';

const EditForm = (props) => {
    if (props.view)
        return (
            <>
                <h1>Edit the widget</h1>
                <props.view
                    serviceSelected={{id: props.widget.service_id}}
                    widgetSelected={{id: props.widget.widget_id}}
                    widgetID={props.id}
                    widget={props.widget}
                    handleEdit={props.handleEdit}
                />
            </>
        )
    return null;
}

const EditWidgetPage = (props) => {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <WidgetForm>
                <EditForm id={props.id} widget={props.widget?.widget} view={props.widget?.widgetService?.configuration} handleEdit={props.handleEdit} />
            </WidgetForm>
        </Modal>
    );
};

export default EditWidgetPage;