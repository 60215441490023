import * as React from "react"

function SettingsSVG(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"Stockholm-icons / General / Settings-2"}</title>
        <path
          d="M5 8.686V5h3.686l2.9-2.9 2.9 2.9H19v4.515L21.485 12 19 14.485V19h-4.515l-2.9 2.9L8.687 19H5v-3.686L1.686 12 5 8.686zM12 15a3 3 0 100-6 3 3 0 000 6z"
          fill="currentColor"
        />
    </svg>
  )
}

export default SettingsSVG