import React, { useCallback } from 'react';
import update from 'immutability-helper';

import DashboardPage from 'pages/Dashboard/Dashboard';
import useFirebase from 'hooks/Firebase/useFirebase';
import { v4 as uuidv4 } from 'uuid';
import services from 'utils/services';
import { WEATHER_CITY_DATA } from 'utils/api';
import { useDispatch } from 'react-redux';
import {setWidgets} from 'actions/widgets';
import {VIDEO_YOUTUBE_REGEX} from 'utils/texts';

const Dashboard = () => {
    const [{data}, addDatabase] = useFirebase();
    const [widgets, setViewWidgets] = React.useState([])
    const dispatch = useDispatch()

    React.useEffect(() => {
        const widgetsParsed = data?.widgets?.map(el => {
            const serviceData = services.filter(service => service.id === el.service_id)[0];
            const widget = serviceData?.widgets?.filter(widget => widget.id === el.widget_id)[0];

            const data = {
                id: uuidv4(),
                title: serviceData.name,
                view: widget.view,
                time: parseInt(el.timer),
                unit: el.degrees === "metrics" ? undefined : el.degrees,
                img: serviceData.img,
                widget: el,
                widgetService: widget
            }

            if (serviceData?.id === 1) {
                const city = WEATHER_CITY_DATA.filter(citEl => citEl.name?.toLowerCase() === el.city?.toLowerCase())[0]

                data.city = city
            }

            if (serviceData?.id === 2) {
                if (el.video) {
                    const re = new RegExp(VIDEO_YOUTUBE_REGEX);
                    const dataRe = re.exec(el.video)

                    const video_id = dataRe[3]

                    data.video_id = video_id;
                } else if (el.channel) {
                    data.channel = el.channel
                }
            }

            if (serviceData?.id === 3) {
                if (el.base) {
                    data.base = el.base;
                    data.symbol = el.symbol;
                } else if (el.stock) {
                    data.stock = el.stock
                }
            }

            if (!serviceData || !widget) {
                return {
                    title: "Error"
                }
            }
            return data;
        })

        dispatch(setWidgets(widgetsParsed))
        setViewWidgets(widgetsParsed);
    }, [data])

    const moveWidget = useCallback((dragIndex, hoverIndex) => {
        const dragWidget = widgets[dragIndex];

        const movedWidgets = update(widgets, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragWidget]
            ]
        })
        setViewWidgets(movedWidgets)
        addDatabase.setWidget("users", movedWidgets.map(el => el.widget), false)
    }, [widgets])


    return (
        <DashboardPage moveWidget={moveWidget} widgets={widgets} />
    );
};

export default Dashboard;