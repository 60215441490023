import FirebaseProvider from 'hooks/Firebase/FirebaseProvider';
import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Router from 'utils/router';
import configureStore from 'utils/store';

import './assets/scss/app.scss';

ReactDOM.render(
  <Provider store={configureStore()}>
    <FirebaseProvider>
      <Router />
    </FirebaseProvider>
  </Provider>,
  document.getElementById('root')
);
