import React from 'react';
import NavBar from './Header/NavBar';
import Dashboard from 'containers/Dashboard/Dashboard';
import Configuration from 'containers/Configuration/Configuration';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoutes from 'utils/private-routes';
import LoadingScreen from 'react-loading-screen';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 800)
  }, [])

  return (
    <div className="App">
        <NavBar />
        <ToastContainer />
        <DndProvider backend={HTML5Backend}>
          <LoadingScreen
              loading={loading}
              logoSrc={"/assets/img/DashboardLogoSVG.svg"}
              text="Dashboard"
          >
              <div className="px-3">
                <Switch>
                  <PrivateRoutes path="/dashboard" component={Dashboard} />
                  <PrivateRoutes path="/configuration" component={Configuration} />
                  <Redirect to="/dashboard" />
                </Switch>
              </div>
          </LoadingScreen>
        </DndProvider>
        {/* <Footer /> */}
    </div>
  );
}

export default App;
