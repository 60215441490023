import React from 'react';
import WidgetPage from 'pages/Dashboard/Widgets/Widget'
import useTimer from 'hooks/useTimer';
import { useDrag, useDrop } from 'react-dnd';

const itemTypes = {
    WIDGET: 'widget'
}

const Widget = (props) => {
    const {time, onReload, index, moveWidget, id} = props
    const [timer, restartTimer] = useTimer(time + 2000, onReload)
    const ref = React.useRef(null);
    const [, drop] = useDrop({
        accept: itemTypes.WIDGET,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveWidget(dragIndex, hoverIndex);
            item.index = hoverIndex;
        }
    })

    const [{ isDragging }, drag] = useDrag({
        item: { type: itemTypes.WIDGET, id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        })
    })
    const opacity = React.useMemo(() => isDragging ? 0.2 : 1, [isDragging]);

    drag(drop(ref));

    return (
        <div className="h-100" ref={ref}>
        <WidgetPage
            timer={timer}
            restartTimer={restartTimer}
            opacity={opacity}
            {...props}
        />
        </div>
    );
};

export default Widget;