import useFirebase from 'hooks/Firebase/useFirebase';
import React from 'react';
import DeleteWidgetPage from '../../../pages/Dashboard/DeleteWidget/DeleteWidget';

const DeleteWidget = (props) => {
    const [,, deleteElement] = useFirebase();

    const handleDelete = () => {
        deleteElement.widget("users", props.widget?.widget)
        props.onHide()
    }

    return (
        <DeleteWidgetPage {...props} handleDelete={handleDelete} />
    );
};

export default DeleteWidget;