import { FirebaseContext } from 'hooks/Firebase/FirebaseProvider';
import React from 'react';

import { Redirect, Route } from 'react-router-dom';

const PrivateRoutes = ({ component: Component, ...rest }) => {
    const {user} = React.useContext(FirebaseContext);

    return <Route {...rest} render={(props) => (
        !!user ?
        <Component {...props} /> :
        <Redirect to="/login" />
    )} />
};

export default PrivateRoutes;