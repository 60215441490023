import React from 'react';
import { Link } from 'react-router-dom';
import NavElement from './NavElement';
import useFirebase from 'hooks/Firebase/useFirebase';
import { Avatar } from 'components/profile/avatar';

const navList = [
    {
        id: 0,
        title: "All Widgets",
        href: "/dashboard"
    },
    {
        id: 1,
        title: "Configuration",
        href: "/configuration"
    },
]

const NavBar = () => {
    const [{ user }] = useFirebase();

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-white shadow p-2 px-3">
            <Link className="navbar-brand font-weight-bolder text-primary" to="/dashboard">
                <img className="pr-1" src="/assets/img/DashboardLogoSVG.svg" alt="dashboard logo" width="40" />
                Dashboard
            </Link>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                <ul className="navbar-nav ml-auto mt-2 mt-lg-0 dropleft">
                    {
                        navList.map(el => <NavElement {...el} isActive={window.location.pathname.includes(el.href)} />)
                    }
                    <li className="nav-item">
                        <div className="dropdown d-flex align-items-center justify-content-center">
                            <div style={{cursor: 'pointer'}} id="userDropdownButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="d-flex justify-content-center align-items-center">
                                {
                                    user.displayName &&
                                    <Link className="text-primary m-0">{user.displayName}</Link>
                                }
                                <Avatar img={user.photoURL} alt="user image" size="xs" />
                            </div>
                            <div className="dropdown-menu" aria-labelledby="userDropdownButton">
                                <Link className="dropdown-item text-primary" to="/logout">Logout</Link>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default NavBar;