import { Form, Formik } from 'formik';
import React from 'react';

const WidgetForm = ({WForm, WInitial, WValidate, handleSubmit, children}) => {
    return (
        <div className="p-2 pt-0">
            <Formik
                initialValues={WInitial}
                validationSchema={WValidate}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {(FProps) => {
                    return (
                        <Form className="form" onSubmit={FProps.handleSubmit}>
                            {
                                React.Children.map(children, child => {
                                    return React.cloneElement(child, {
                                        ...FProps,
                                        formData: WForm
                                    }, null)
                                })
                            }
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
};

export default WidgetForm;