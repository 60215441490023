import React from 'react';
import WeatherLittlePage from 'pages/Dashboard/Widgets/Weather/WeatherLittle';
import useRequest from 'hooks/use-request';
import { WEATHER_API_URL } from 'utils/api';

const WeatherLittle = (props) => {
    const [handleRequest, status] = useRequest()
    const [data, setData] = React.useState()

    React.useEffect(() => {
        if (props.demo) {
            return
        }
        handleRequest("get", WEATHER_API_URL(props.city?.ID, props.unit), {}, {}, (data, status) => {
            if (status === "Done") {
                setData(data)
            } else if (status === "Error") {
                console.error(data)
            }
        }, true)
        // eslint-disable-next-line
    }, [props.cityID])

    const handleReload = () => {
        handleRequest("get", WEATHER_API_URL(props.city?.ID, props.unit), {}, {}, (data, status) => {
            if (status === "Done") {
                setData(data)
            } else if (status === "Error") {
                console.error(data)
            }
        }, true)
    }

    return (
        <WeatherLittlePage
            status={status}
            data={data}
            handleReload={handleReload}
            {...props}
        />
    );
};

export default WeatherLittle;