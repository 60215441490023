import React from 'react';
import Widget from 'containers/Dashboard/Widgets/Widget';
import { camelToSnake } from '../../../../utils/utils';
import { MoonLoader } from 'react-spinners';

const ExchangeLittlePage = (props) => {
    const RateData = props.data?.rates

    let symbolName = "";
    if (props.symbol)
        symbolName = camelToSnake(props.symbol)

    return (
        <div className="col-12 col-md-3 py-1">
            <Widget {...props} onRelaod={props.handleReload}>
                {
                    props.status === "Loading" &&
                    <div className="position-absolute d-flex justify-content-center align-items-center" style={{top: 0, left: 0, right: 0, bottom: 0}}>
                        <MoonLoader
                            size={60}
                            color={"#1faf46"}
                            loading={props.status === "Loading"}
                        />
                    </div>
                }
                {
                    RateData ?
                    <div className="row w-100 h-100 py-2">
                        <div className="w-100 h-50 d-flex justify-content-between align-items-center flex-wrap border-bottom">
                            <div className="pr-2">
                                <h1 className="text-secondary m-0">{props.base}</h1>
                                <small className="text-muted m-0">Base</small>
                            </div>
                            <h1 className="text-primary m-0">1.0000</h1>
                        </div>
                        <div className="w-100 h-50 d-flex justify-content-between align-items-center flex-wrap">
                            <div>
                                <h1 className="text-secondary m-0">{props.symbol}</h1>
                                <small className="text-muted m-0">Symbol</small>
                            </div>
                            <h1 className="text-primary m-0">{RateData[symbolName]}</h1>
                        </div>
                    </div>
                    :
                        props.demo ?
                        <div className="row w-100 h-100 py-2">
                            <div className="w-100 h-50 d-flex justify-content-between align-items-center flex-wrap border-bottom">
                                <div className="pr-2">
                                    <div className="bg-dark mb-1" style={{height: 30, width: 60}}></div>
                                    <small className="text-muted m-0">Base</small>
                                </div>
                                <h1 className="text-primary m-0">1.0000</h1>
                            </div>
                            <div className="w-100 h-50 d-flex justify-content-between align-items-center flex-wrap pt-1">
                                <div>
                                    <div className="bg-dark mb-1" style={{height: 30, width: 60}}></div>
                                    <small className="text-muted m-0">Symbol</small>
                                </div>
                                <div className="bg-dark mb-1" style={{height: 40, width: 80}}></div>
                            </div>
                        </div>
                        :
                        <p>We can't find your rates...</p>
                }
            </Widget>
        </div>
    );
};

export default ExchangeLittlePage;