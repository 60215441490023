const widgets = {
    widgets: []
};

const widgetReducer = (state = widgets, action) => {
    switch (action.type) {
        case 'SET_WIDGETS':
            return {
                ...state,
                widgets: action.payload
            }
        default:
            return state;
    }
}

export default widgetReducer;