import React from 'react';
import Widget from 'containers/Dashboard/Widgets/Widget';
import MoonLoader from "react-spinners/MoonLoader";
import moment from 'moment'

const WeatherGrid = (props) => {
    return (
        <div className="row mb-3" style={{height: "80%"}}>
            {
                Object.keys(props.data)?.filter(el => props.data[el].length).sort((a, b) => {
                    return props.data[a][0].dt - props.data[b][0].dt
                }).slice(0,3).map((el, i) => {
                    return <div key={el} className={"col-12 col-md-4 d-flex flex-column justify-content-center " + (i !== 2 && window.innerWidth >= 760 && " border-right")}>
                        <p className="font-weight-bolder text-center text-secondary m-0">{moment.unix(props.data[el][0].dt).format("DD MMM")}</p>
                        <div className="d-flex flex-md-column justify-content-center align-items-center h-100">
                            <img src={`http://openweathermap.org/img/wn/${props.data[el][0]?.weather[0]?.icon}@2x.png`} alt={props.data[el][0]?.weather[0]?.description} />
                            <h4 className="m-0 text-muted pr-2 pr-md-0">{props.data[el][0]?.weather[0]?.main}</h4>
                            <h2 className="m-0 text-primary text-center pt-md-2">{Math.round(props.data[el][0]?.main?.temp)}{props.unit === "imperial" ? "ºF" : "ºC"}</h2>
                        </div>
                    </div>
                })
            }
        </div>
    )
}

const WeatherMediumPage = (props) => {
    const {status, handleReload, data} = props;

    return (
        <div className="col-12 col-md-6 py-1">
            <Widget {...props} onReload={handleReload}>
                {
                    status === "Loading" &&
                    <div className="position-absolute d-flex justify-content-center align-items-center" style={{top: 0, left: 0, right: 0, bottom: 0}}>
                        <MoonLoader
                            size={60}
                            color={"#1faf46"}
                            loading={status === "Loading"}
                        />
                    </div>
                }
                {
                    data ?
                    <div className="w-100">
                        <h1 className="text-dark">Forecast for {props.city?.name}</h1>
                        <WeatherGrid data={data} unit={props.unit} />
                    </div>
                    :
                    (
                        props.demo ?
                        <div className="w-100">
                            <h1 className="text-dark">Forecast for</h1>
                            <div className="row pb-3">
                                <div className="col-12 col-md-4" style={{height: 150}}><div className="bg-dark w-100 h-100"></div></div>
                                <div className="col-12 col-md-4" style={{height: 150}}><div className="bg-dark w-100 h-100"></div></div>
                                <div className="col-12 col-md-4" style={{height: 150}}><div className="bg-dark w-100 h-100"></div></div>
                            </div>
                        </div>
                        :
                        <p>An error occur getting the weather information...</p>
                    )
                }
            </Widget>
        </div>
    );
};

export default WeatherMediumPage;