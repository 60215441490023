import React from 'react';

const ConfigurationServicesElement = (props) => {
    return (
        <div style={{cursor: 'pointer'}} className={"py-2 px-1 d-flex justify-content-between align-items-center border-bottom config-link" + (props.service.id === props.serviceSelected?.id ? " bg-secondary rounded" : "")} onClick={() => props.onSelect(props.service)}>
            <p className="m-0 px-1">{props.service.name}</p>
            <img src="/assets/media/svg/icons/Navigation/Angle-right.svg" alt="" />
        </div>
    );
};

export default ConfigurationServicesElement;