import React from 'react';

const useTimer = (time, onReload) => {
    const [lastDate, setLastDate] = React.useState(+new Date().setMilliseconds(+new Date().getMilliseconds() + time))
    const [timer, setTimer] = React.useState({
        minutes: 0,
        seconds: 0
    })

    React.useEffect(() => {
        setLastDate(+new Date().setMilliseconds(+new Date().getMilliseconds() + time))
    }, [time])

    React.useEffect(() => {
        if (!time) {
            return
        }
        const timeout = setTimeout(() => {
            const difference = lastDate - +new Date()

            if (difference > 1000) {
                setTimer({
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60)
                })
            } else {
                setTimer({
                    minutes: 0,
                    seconds: 0
                })
                onRestart()
            }
        }, 1000)
        return () => clearTimeout(timeout)
    }, [timer])

    const onRestart = () => {
        if (onReload) {
            onReload()
        }
        setLastDate(+new Date().setMilliseconds(+new Date().getMilliseconds() + time))
    }

    return [timer, onRestart];
};

export default useTimer;