import React from 'react';
import Widget from 'containers/Dashboard/Widgets/Widget';
import Avatar from '../../../../components/profile/avatar/avatar';
import { MoonLoader } from 'react-spinners';

const YoutubeBigPage = (props) => {
    const channelData = props.data?.items[0];

    return (
        <div className="col-12 col-md-9 py-1">
            <Widget {...props} onReload={props.handleReload}>
                {
                    props.status === "Loading" &&
                    <div className="position-absolute d-flex justify-content-center align-items-center" style={{top: 0, left: 0, right: 0, bottom: 0}}>
                        <MoonLoader
                            size={60}
                            color={"#1faf46"}
                            loading={props.status === "Loading"}
                        />
                    </div>
                }
                {
                    props.data ?
                    <div className="row py-3 w-100">
                        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                            <Avatar img={channelData?.snippet?.thumbnails?.high?.url} size="xl" />
                        </div>
                        <div className="col-12 col-md-6 d-flex justify-content-center flex-column pl-4">
                            <h3 className="text-secondary">{channelData?.snippet?.localized?.title}</h3>
                            <h1 className="text-primary m-0">{channelData?.statistics?.subscriber_count}</h1>
                            <small className="text-muted">Subscribers</small>
                        </div>
                    </div>
                    :
                        props.demo ?
                        <div className="row py-3 w-100">
                            <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                                <div className="bg-dark mb-1 rounded-circle" style={{height: 120, width: 120}}></div>
                            </div>
                            <div className="col-12 col-md-6 d-flex justify-content-center flex-column pl-4">
                                <div className="bg-dark mb-1" style={{height: 40, width: 120}}></div>
                                <div className="bg-dark mb-1" style={{height: 50, width: 160}}></div>
                                <small className="text-muted">Subscribers</small>
                            </div>
                        </div>
                        :
                        <p>We didn't found this channel...</p>
                }
            </Widget>
        </div>
    );
};

export default YoutubeBigPage;