import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';


import App from '../pages/App';
import Login from '../containers/Auth/Login';
import Logout from '../containers/Auth/Logout';
import About from '../containers/About/About';

import PrivateRoutes from './private-routes';
import { ToastContainer } from 'react-toastify';

const Router = () => {

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={Login} exact />
                <Route path="/logout" component={Logout} exact />
                <Route path="/about.json" component={About} exact />
                <PrivateRoutes path="/" component={App} />
                <Redirect to="/login" />
            </Switch>
            <ToastContainer />
        </BrowserRouter>
    );
};

export default Router;