import React from 'react';
import YoutubeLittleFormPage from 'pages/Configuration/ConfigurationViews/ConfigurationWidget/WidgetsForms/Youtube/YoutubeLittle';
import * as Yup from 'yup';
import WidgetForm from '../WidgetForm';
import { useSelector } from 'react-redux';
import useFirebase from '../../../../hooks/Firebase/useFirebase';
import { toast } from 'react-toastify';

const YLForm = {
    video: {
        name: "video",
        placeholder: "https://www.youtube.com/...",
        label: "Video URL",
        required: true
    },
    timer: {
        name: "timer",
        placeholder: "Timer (ms)",
        label: "Refresh timer",
        required: true
    }
}

const YLValidate = Yup.object().shape({
    [YLForm.timer.name]: Yup.number().required("Required"),
    [YLForm.video.name]: Yup.string().required("Required")
})

const YoutubeLittleForm = (props) => {
    const [, addElement] = useFirebase();
    const [YLInitial, setYLInitial] = React.useState({
        [YLForm.timer.name]: "",
        [YLForm.video.name]: ""
    })
    const {widgets} = useSelector(state => state.widgets);

    React.useEffect(() => {
        if (props.widget) {
            setYLInitial(props.widget);
        }
    }, [props.widget])

    const handleSubmit = (values, formikBag) => {
        if (props.handleEdit) {
            props.handleEdit(values);
            const valuesParsed = widgets?.map(el => {
                if (el.id === props.widgetID) {
                    return {
                        ...el.widget,
                        ...values,
                    };
                }
                return el.widget;
            })
            addElement.setWidget("users", valuesParsed)
            toast("The widget was updated !");
            return
        }
        addElement.widget("users", {
            service_id: props.serviceSelected.id,
            widget_id: props.widgetSelected.id,
            timer: values[YLForm.timer.name],
            video: values[YLForm.video.name]
        });

        formikBag.resetForm()
        toast("The widget was added to your dashboard !");
    }

    return (
        <WidgetForm WForm={YLForm} WInitial={YLInitial} WValidate={YLValidate} handleSubmit={handleSubmit}>
            <YoutubeLittleFormPage handleEdit={props.handleEdit} />
        </WidgetForm>
    );
};

export default YoutubeLittleForm;