import React from 'react';
import Card from 'components/design/card/card';
import ReloadSVG from 'components/svg/ReloadSVG';
import SettingsSVG from 'components/svg/SettingsSVG';
import {Link} from 'react-router-dom';

const TIMER_VIEW = (nb) => nb < 10 ? '0' + nb : nb

const WidgetPage = ({ children, onConfigure, title, img, timer, restartTimer, opacity, ...props }) => {
    return (
        <Card shadow color="white" className="position-relative my-1 h-100" attr={{style: {opacity, cursor: "move", minHeight: "100%"}}}>
            <div className="position-absolute" style={{ bottom: 10, right: 15, zIndex: 99}}>
                <div className="d-flex align-items-center">
                    <small className="m-0 text-muted">{TIMER_VIEW(timer.minutes)}:{TIMER_VIEW(timer.seconds)}</small>
                    <div className="shadow pl-1" onClick={restartTimer} style={{cursor: "pointer"}} >
                        <ReloadSVG className="text-secondary" />
                    </div>
                </div>
            </div>
            <div className="card-title d-flex align-items-center justify-content-between px-2 py-1 m-0">
                <div className="d-flex align-items-center">
                    <img className="mr-1" src={img} alt={title} width="20" />
                    <h5 className="m-0">{title}</h5>
                </div>
                <div className="shadow dropleft" style={{cursor: "pointer", zIndex: 99}} >
                    <SettingsSVG id="menuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="text-primary" />
                    <div className="dropdown-menu" aria-labelledby="menuButton">
                        <Link className="dropdown-item" to={`/dashboard/modify/${props.id}`}>Modify</Link>
                        <Link className="dropdown-item" to={`/dashboard/delete/${props.id}`}>Delete</Link>
                    </div>
                </div>
            </div>
            <div className="card-body py-1 position-relative h-100 d-flex justify-content-center align-items-center" >
                {children}
            </div>
        </Card>
    );
};

export default WidgetPage;