import Card from 'components/design/card/card';
import React from 'react';
import ConfigurationServicesElement from './ConfigurationServicesElement';

const ConfigurationServicesList = (props) => {
    return (
        <Card className="p-2 bg-white shadow h-100">
            <h2>Services</h2>
            <div className="p-1">
                {
                    props.services?.map(el => <ConfigurationServicesElement service={el} serviceSelected={props.serviceSelected} onSelect={props.onSelect} />)
                }
            </div>
        </Card>
    );
};

export default ConfigurationServicesList;