import React from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import EditWidget from 'containers/Dashboard/EditWidget/EditWidget';
import DeleteWidget from 'containers/Dashboard/DeleteWidget/DeleteWidget';

const DashboardPage = (props) => {
    const {moveWidget, widgets} = props;

    const renderWidget = (widget, index) => {
        return (<widget.view key={widget.id} {...widget} index={index} moveWidget={moveWidget} />)
    }

    return (
        <div className="pt-1">
            <div className="row pb-4">
                {
                    !widgets?.length ?
                    <div className="py-5 w-100">
                        <h1 className="text-dark text-center">Add some cool widgets <Link className="text-primary" to="/configuration">here</Link></h1>
                    </div>
                    :
                    widgets?.map(renderWidget)
                }
            </div>
            <Switch>
                <Route path="/dashboard/modify/:widgetID">
                    {({match, history}) => (
                        <EditWidget
                            show={match != null}
                            id={match && match.params.widgetID}
                            onHide={() => {
                                history.push("/dashboard");
                            }}
                            widget={widgets?.filter(el => el.id === match.params.widgetID)[0]}
                        />
                    )}
                </Route>
                <Route path="/dashboard/delete/:widgetID">
                    {({match, history}) => {
                        return (
                            <DeleteWidget
                                show={match != null}
                                id={match && match.params.widgetID}
                                onHide={() => {
                                    history.push("/dashboard");
                                }}
                                widget={widgets?.filter(el => el.id === match.params.widgetID)[0]}
                            />
                        )
                    }}
                </Route>
            </Switch>
        </div>
    );
};

export default DashboardPage;