import React from 'react';
import ConfigurationPage from '../../pages/Configuration/ConfigurationPage';
import Services from 'utils/services';

const Configuration = () => {
    const [selService, setSelService] = React.useState();

    return (
        <ConfigurationPage
            services={Services}
            serviceSelected={selService}
            onSelect={setSelService}
        />
    );
};

export default Configuration;