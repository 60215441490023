import { Button } from 'components/form/button';
import React from 'react';
import { Modal } from 'react-bootstrap';

const DeleteWidgetPage = ({onHide, show, id, handleDelete}) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <>
                <div className="p-2">
                    <h1 className="text-dark">Delete a widget</h1>
                </div>
                <div className="card-body">
                    <h5 className="m-0 font-weight-light">Do you really want to delete this widget ?</h5>
                </div>
                <div className="p-2 d-flex justify-content-end">
                    <div className="mr-1">
                        <Button
                            onClick={onHide}
                            className="btn-elevate"
                            color="dark"
                        >
                            Cancel
                        </Button>
                    </div>
                    <Button
                        onClick={handleDelete}
                        className="btn-elevate"
                        color="primary"
                    >
                        Delete
                    </Button>
                </div>
            </>
        </Modal>
    );
};

export default DeleteWidgetPage;