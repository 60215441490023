import React from 'react';
import firebase from 'firebase';
import "firebase/firestore";

const config = {
    apiKey: 'AIzaSyCZzs_9gvCZMetJQJTiKUR-y4aVX6cZQu4',
    authDomain: 'liquid-cumulus-240115.firebaseapp.com',
    databaseUrl: 'https://liquid-cumulus-240115.firebaseio.com/',
    projectId: "liquid-cumulus-240115"
};
firebase.initializeApp(config);

export const FirebaseContext = React.createContext({});

const FirebaseProvider = ({ children }) => {
    const [user, setUser] = React.useState(null)
    const [data, setData] = React.useState(null);
    const [verifEmail, setVerifEmail] = React.useState(false)

    React.useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user && !user.emailVerified && user.providerData[0].providerId === "password") {
                setVerifEmail(true)
                user.sendEmailVerification().then(() => {
                    console.log("Email send !")
                })
                return
            }
            setUser(user);
            if (user) {
                const db = firebase.firestore();
                const doc = db.collection("users").doc(user.uid)
                doc.get().then((d) => {
                    if (d.exists) {
                        setData(d.data());
                    } else {
                        doc.set({})
                    }
                })
            }
        });
    }, []);

    const getDataFromDatabase = () => {
        const db = firebase.firestore();

        const doc = db.collection("users").doc(user.uid)
        doc.get().then((d) => {
            if (d.exists) {
                setData(d.data());
            }
        })
    }

    return (
        <FirebaseContext.Provider value={{
            app: firebase,
            user: user,
            data: data,
            verifyEmail: verifEmail,
            updateData: getDataFromDatabase
        }}>
            {children}
        </FirebaseContext.Provider>
    );
};

export default FirebaseProvider;