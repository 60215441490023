import React from 'react';

const Card = (props) => {
    const classNames = [
        "card",
        `bg-${props.color || "primary"}`,
        props.shadow && "shadow",
        props.className
    ].filter(el => el).join(" ")

    return (
        <div className={classNames} {...props.attr}>
            {props.children}
        </div>
    );
};

export default Card;