// Routes
import data from './weather.json';

export const BASE_URL = "http://back:5000"

export const WEATHER_API_URL = (city_id, unit = "metric") => `https://api.openweathermap.org/data/2.5/weather?id=${city_id}&units=${unit}&appid=4181938386fc0b440cb9dd9a28868c73`
export const WEATHER_FORECAST_API_URL = (city_id, unit = "metric") => `https://api.openweathermap.org/data/2.5/forecast?id=${city_id}&units=${unit}&appid=4181938386fc0b440cb9dd9a28868c73`

export const YOUTUBE_CHANNEL_API_URL = (username) => `https://youtube.googleapis.com/youtube/v3/channels?part=snippet%2CcontentDetails%2Cstatistics&forUsername=${username}&key=AIzaSyAG7EMb2OCwB9TZDBfY7EtrbKp2J1znDPA`

export const YOUTUBE_VIDEO_API_URL = (video_id) => `https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&id=${video_id}&key=AIzaSyAG7EMb2OCwB9TZDBfY7EtrbKp2J1znDPA`

export const YOUTUBE_COMMENTS_API_URL = (video_id) => `https://www.googleapis.com/youtube/v3/commentThreads?key=AIzaSyAG7EMb2OCwB9TZDBfY7EtrbKp2J1znDPA&textFormat=plainText&part=snippet&videoId=${video_id}&maxResults=3`

export const EXCHANGE_DATA = (symbol, base) => `https://api.exchangeratesapi.io/latest?symbols=${symbol}&base=${base}`;

export const EXCHANGE_DATA_STOCK = (stock) => `http://api.marketstack.com/v1/eod?access_key=c448aa99e1b959aec66dcd4d872c22bc&symbols=${stock}`;

export const WEATHER_CITY_DATA = data.map(el => ({
    name: el.name,
    ID: el.id
}))

