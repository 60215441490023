import React from 'react';
import Widget from 'containers/Dashboard/Widgets/Widget';
import MoonLoader from "react-spinners/MoonLoader";

const WeatherLittlePage = (props) => {
    const {data, handleReload, status} = props;

    return (
        <div className="col-12 col-md-3 py-1">
            <Widget {...props} onReload={handleReload}>
                {
                    status === "Loading" &&
                    <div className="position-absolute d-flex justify-content-center align-items-center" style={{top: 0, left: 0, right: 0, bottom: 0}}>
                        <MoonLoader
                            size={60}
                            color={"#1faf46"}
                            loading={status === "Loading"}
                        />
                    </div>
                }
                <div className="d-flex justify-content-center align-items-center flex-column pb-2">
                    {
                        data ?
                        <>
                            <img src={`http://openweathermap.org/img/wn/${data?.weather[0]?.icon}@4x.png`} alt={data?.weather[0]?.description} />
                            <h3 className="text-dark m-0 text-center">{props.city?.name}</h3>
                            <h5 className="text-muted">{data?.weather[0]?.main}</h5>
                            <h2 className="text-primary">{Math.floor(data?.main?.temp)}{props.unit === "imperial" ? "ºF" : "ºC"}</h2>
                        </>
                        :
                        props.demo ?
                        <>
                            <div className="bg-dark mb-1" style={{height: 80, width: 80}}></div>
                            <div className="bg-dark mb-1" style={{height: 30, width: 150}}></div>
                            <div className="bg-dark mb-1" style={{height: 20, width: 80}}></div>
                            <h2 className="text-primary">{props.unit === "imperial" ? "ºF" : "ºC"}</h2>
                        </>
                        :
                        <p>We didn't found this city... Try with common cities.</p>
                    }
                </div>
            </Widget>
        </div>
    );
};

export default WeatherLittlePage;