import React from 'react';
import WeatherMediumFormPage from 'pages/Configuration/ConfigurationViews/ConfigurationWidget/WidgetsForms/Weather/WeatherMedium';
import * as Yup from 'yup';
import WidgetForm from '../WidgetForm';
import useFirebase from 'hooks/Firebase/useFirebase';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const WMForm = {
    city: {
        name: "city",
        placeholder: "City",
        label: "City",
        required: true,
    },
    degrees: {
        name: "degrees",
        placeholder: "Metrics or Imperial",
        label: "Metrics or Imperial"
    },
    timer: {
        name: "timer",
        placeholder: "Timer (ms)",
        label: "Refresh timer",
        required: true
    }
}

const WMValidate = Yup.object().shape({
    [WMForm.city.name]: Yup.string().required("Required"),
    [WMForm.degrees.name]: Yup.string().oneOf(["metrics", "imperial"], "It only can be 'metrics' or 'imperial'").notRequired(),
    [WMForm.timer.name]: Yup.number().required("Required")
})

const WeatherMediumForm = (props) => {
    const [, addElement] = useFirebase()
    const [WMInitial, setWMInitial] = React.useState({
        [WMForm.city.name]: "",
        [WMForm.degrees.name]: "metrics",
        [WMForm.timer.name]: ""
    })
    const {widgets} = useSelector(state => state.widgets);

    React.useEffect(() => {
        if (props.widget) {
            setWMInitial(props.widget);
        }
    }, [props.widget])

    const handleSubmit = (values, formikBag) => {
        if (props.handleEdit) {
            props.handleEdit(values);
            const valuesParsed = widgets?.map(el => {
                if (el.id === props.widgetID) {
                    return {
                        ...el.widget,
                        ...values,
                    };
                }
                return el.widget;
            })
            addElement.setWidget("users", valuesParsed)
            toast("The widget was updated !");
            return
        }
        addElement.widget("users", {
            service_id: props.serviceSelected.id,
            widget_id: props.widgetSelected.id,
            timer: values[WMForm.timer.name],
            degrees: values[WMForm.degrees.name],
            city: values[WMForm.city.name]
        });

        formikBag.resetForm()
        toast("The widget was added to your dashboard !");
    }

    return (
        <WidgetForm WForm={WMForm} WInitial={WMInitial} WValidate={WMValidate} handleSubmit={handleSubmit}>
            <WeatherMediumFormPage handleEdit={props.handleEdit} />
        </WidgetForm>
    );
};
export default WeatherMediumForm;