import React from 'react';
import ExchangeMediumPage from 'pages/Dashboard/Widgets/Exchange/ExchangeMedium';
import useRequest from '../../../../hooks/use-request';
import { EXCHANGE_DATA_STOCK } from '../../../../utils/api';

const ExchangeMedium = (props) => {
    const [handleRequest, status] = useRequest()
    const [data, setData] = React.useState()
    const [error, setError] = React.useState()

    React.useEffect(() => {
        if (props.demo) {
            return
        }
        handleRequest("get", EXCHANGE_DATA_STOCK(props.stock), {}, {}, (data, status) => {
            if (status === "Done") {
                setData(data)
            } else if (status === "Error") {
                setError("There was an error loading http request from https, please upgrade the API_KEY to a one who accept the https requests.")
                console.error(data)
            }
        }, true)
        // eslint-disable-next-line
    }, [props.cityID])

    const handleReload = () => {
        handleRequest("get", EXCHANGE_DATA_STOCK(props.stock), {}, {}, (data, status) => {
            if (status === "Done") {
                setData(data)
            } else if (status === "Error") {
                setError("There was an error loading http request from https, please upgrade the API_KEY to a one who accept the https requests.")
                console.error(data)
            }
        }, true)
    }
    return (
        <ExchangeMediumPage
            {...props}
            handleReload={handleReload}
            data={data}
            error={error}
            status={status}
        />
    );
};

export default ExchangeMedium;