import YoutubeLittlePage from 'pages/Dashboard/Widgets/Youtube/YoutubeLittle';
import React from 'react';
import useRequest from '../../../../hooks/use-request';
import { YOUTUBE_VIDEO_API_URL } from '../../../../utils/api';

const YoutubeLittle = (props) => {
    const [handleRequest, status] = useRequest();
    const [data, setData] = React.useState()

    React.useEffect(() => {
        if (props.demo) {
            return
        }
        handleRequest("get", YOUTUBE_VIDEO_API_URL(props.video_id), {}, {}, (data, status) => {
            if (status === "Done") {
                setData(data)
            } else if (status === "Error") {
                console.error(data);
            }
        }, true)
    }, [])

    const handleReload = () => {
        handleRequest("get", YOUTUBE_VIDEO_API_URL(props.video_id), {}, {}, (data, status) => {
            if (status === "Done") {
                setData(data)
            } else if (status === "Error") {
                console.error(data);
            }
        }, true)
    }

    return (
        <YoutubeLittlePage
            {...props}
            data={data}
            status={status}
            handleReload={handleReload}
        />
    );
};

export default YoutubeLittle;