import React from 'react';
import { Link } from 'react-router-dom';

const NavElement = ({ title, isActive, href }) => {
    return (
        <li className='nav-item'>
            <Link to={href} className={`nav-link ${isActive ? "text-secondary underline" : "text-primary"}`}>{title}</Link>
        </li>
    )
}

export default NavElement;