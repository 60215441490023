import React from 'react';
import WeatherMediumPage from 'pages/Dashboard/Widgets/Weather/WeatherMedium';
import { WEATHER_FORECAST_API_URL } from 'utils/api';
import useRequest from 'hooks/use-request';

const HOURS = [13]

const setForecastData = (data) => {
    let forecast = {}
    data?.list?.forEach(el => {
        const date = new Date(el.dt * 1000).getDate()
        forecast[date] = []
    })

    data?.list?.forEach(el => {
        const hour = new Date(el.dt * 1000).getHours()

        if (HOURS.includes(hour)) {
            const date = new Date(el.dt * 1000).getDate()
            forecast[date] = [...forecast[date], el]
        }
    })

    return forecast
}

const WeatherMedium = (props) => {
    const [handleRequest, status] = useRequest()
    const [data, setData] = React.useState()

    React.useEffect(() => {
        if (props.demo) {
            return;
        }
        handleRequest("get", WEATHER_FORECAST_API_URL(props.city?.ID, props.unit), {}, {}, (data, status) => {
            if (status === "Done") {
                setData(setForecastData(data))
            } else if (status === "Error") {
                console.error(data)
            }
        }, true)
        // eslint-disable-next-line
    }, [props.cityID])

    const handleReload = () => {
        handleRequest("get", WEATHER_FORECAST_API_URL(props.city?.ID, props.unit), {}, {}, (data, status) => {
            if (status === "Done") {
                setData(setForecastData(data))
            } else if (status === "Error") {
                console.error(data)
            }
        }, true)
    }

    return (
        <WeatherMediumPage
            status={status}
            data={data}
            handleReload={handleReload}
            {...props}
        />
    );
};

export default WeatherMedium;