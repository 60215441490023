import React from 'react';
import Widget from 'containers/Dashboard/Widgets/Widget';
import { MoonLoader } from 'react-spinners';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

const ExchangeChart = (props) => {
    const dataLimited = props.data?.data?.sort((a,b) => +new Date(b.date) - +new Date(a.date)).slice(0, 30)
    const parsedData = dataLimited?.sort((a,b) => +new Date(a.date) - +new Date(b.date)).map(el => {
        return el.open
    })
    const dates = dataLimited?.sort((a,b) => +new Date(a.date) - +new Date(b.date)).map(el => {
        return moment(el.date).format("DD/MM")
    })

    if (!parsedData) {
        return null
    }
    return (
        <div className="col-12 col-md-6 border-right">
            <Line
                data={{
                    labels: dates,
                    datasets: [{
                        backgroundColor: "#1faf46",
                        data: parsedData
                    }],
                    showLine: true,
                }}
                legend={{
                    display:false
                }}
                options={{maintainAspectRatio: false}}
                redraw
            />
        </div>
    )
}

const ExchangeData = (props) => {
    const lastData = props.data?.data[0];

    return (
        <div className="col-12 col-md-6 px-3 d-flex justify-content-between flex-column">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
                <h2 className="text-dark m-0">{props.stock}</h2>
                <div>
                    <h3 className="text-dark m-0">Close</h3>
                    <h1 className="text-primary m-0">{lastData?.close}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <h5 className="text-muted d-flex justify-content-between">Open <span className="text-secondary">{lastData?.open}</span></h5>
                    <h5 className="text-muted d-flex justify-content-between">High <span className="text-secondary">{lastData?.high}</span></h5>
                </div>
                <div className="col-12 col-lg-6">
                    <h5 className="text-muted d-flex justify-content-between">Low <span className="text-secondary">{lastData?.low}</span></h5>
                    <h5 className="text-muted d-flex justify-content-between">Vol <span className="text-secondary">{lastData?.volume}</span></h5>
                </div>
            </div>
        </div>
    )
}

const ExchangeMediumPage = (props) => {
    return (
        <div className="col-12 col-md-6 py-1">
            <Widget {...props} onReload={props.handleReload}>
                {
                    props.status === "Loading" &&
                    <div className="position-absolute d-flex justify-content-center align-items-center" style={{top: 0, left: 0, right: 0, bottom: 0}}>
                        <MoonLoader
                            size={60}
                            color={"#1faf46"}
                            loading={props.status === "Loading"}
                        />
                    </div>
                }
                {
                    props.error &&
                    <p className="text-danger">{props.error}</p>
                }
                {
                    props.data ?
                    <div className="w-100 pb-2">
                        <div className="row">
                            <ExchangeChart {...props} />
                            <ExchangeData {...props} />
                        </div>
                    </div>
                    :
                        props.demo ?
                        <div className="w-100 pb-2">
                            <div className="row">
                                <div className="bg-dark col-12 col-md-6 h-100" style={{height: "100%", minHeight: 200}}>
                                </div>
                                <div className="col-12 col-md-6 px-3 d-flex justify-content-between flex-column">
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <div className="bg-dark mb-1" style={{height: 30, width: 60}}></div>
                                        <div>
                                            <h3 className="text-dark m-0">Close</h3>
                                            <div className="bg-dark mb-1" style={{height: 40, width: 70}}></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <h5 className="text-muted d-flex justify-content-between">Open <div className="bg-dark mb-1" style={{height: 20, width: 50}}></div></h5>
                                            <h5 className="text-muted d-flex justify-content-between">High <div className="bg-dark mb-1" style={{height: 20, width: 50}}></div></h5>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <h5 className="text-muted d-flex justify-content-between">Low <div className="bg-dark mb-1" style={{height: 20, width: 50}}></div></h5>
                                            <h5 className="text-muted d-flex justify-content-between">Vol <div className="bg-dark mb-1" style={{height: 20, width: 50}}></div></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <p>We can't find your symbol...</p>
                }
            </Widget>
        </div>
    );
};

export default ExchangeMediumPage;