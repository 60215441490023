import React from 'react';
import EditWidgetPage from '../../../pages/Dashboard/EditWidget/EditWidget';

const EditWidget = (props) => {
    const handleEdit = (values) => {
        props.onHide()
    }

    return (
        <EditWidgetPage {...props} handleEdit={handleEdit} />
    );
};

export default EditWidget;