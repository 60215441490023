import React from 'react';
import { withRouter } from 'react-router-dom';
import useFirebase from 'hooks/Firebase/useFirebase';

const Logout = ({history}) => {
    const [{app}] = useFirebase();

    React.useEffect(() => {
        app.auth().signOut().then(function() {
            setTimeout(() => {
                history.push("/login")
            }, 1000)
        })
    }, [])

    return (
        <p>Loging out...</p>
    );
};

export default withRouter(Logout);